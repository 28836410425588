<template>
  <div
    class="body-widget-category-children-wrapper"
    :class="[showSquareBorders ? 'show-square-borders' : '']"
    @click="action"
  >
    <img
      class="body-widget-category-children__image"
      :src="getBackgroundImage(image, imageSize, imageSize)"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div
      class="body-widget-category-children__image-overlay"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
    />
    <div
      v-if="highlightedText"
      class="body-widget-category-children__highlighted-text"
      :class="[showSquareBorders ? 'show-square-borders' : '']"
      :style="{ 'background-color': highlightColor }"
    >
      {{ highlightedText }}
    </div>
    <div class="body-widget-category-children__content">
      <div class="body-widget-category-children__content__title" :style="{ 'font-size': fontSize + 'px' }">
        {{ title }}
      </div>
      <body-widget-subtitle-wrapper
        v-if="showSubtitle"
        :card-options="cardOptions"
        :card="card"
        class="body-widget-category-children__content__subtitle"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { getImageUrl, translate } from "@/services/utils.js";
import { cardStyles } from "@/utils/enums/widgetsFrontpage.js";
export default {
  components: {
    BodyWidgetSubtitleWrapper: defineAsyncComponent(
      () => import("@/components/WidgetsFrontpage/Wrappers/BodyWidgetSubtitleWrapper.vue")
    )
  },
  props: {
    fontSize: {
      type: Number,
      required: true
    },
    card: {
      type: Object,
      required: true
    },
    cardOptions: {
      type: Object || null,
      required: true
    },
    showSquareBorders: {
      type: Boolean,
      required: false
    },
    imageSize: {
      type: String,
      required: false,
      default: "_ld"
    }
  },
  emits: ["action"],
  data() {
    return {
      cardStyles
    };
  },
  computed: {
    title() {
      return translate(this.card.translatableName);
    },
    image() {
      return this.card.photographs ? this.card.photographs[0] : null;
    },
    highlightedText() {
      if (
        !this.card?.highlightedText ||
        typeof this.card.highlightedText !== "object" ||
        this.card.highlightedText.length === 0 ||
        translate(this.card.highlightedText).length === 0
      ) {
        return null;
      }
      return translate(this.card.highlightedText);
    },
    highlightColor() {
      if (
        !this.cardOptions.highlightColor ||
        (this.cardOptions.highlightColor === "#000000" &&
          this.cardOptions.highlightColor !== this.$store.state.mainColor)
      ) {
        return this.$store.state.mainColor;
      }
      return this.cardOptions.highlightColor;
    },
    showSubtitle() {
      return this.cardOptions?.showSubtitle && this.cardOptions.subtitleType;
    }
  },
  methods: {
    getBackgroundImage: getImageUrl,
    action() {
      this.$emit("action", this.card);
    }
  }
};
</script>

<style lang="less" scoped>
@import (reference) "../../../assets/styleguide.less";
.show-square-borders {
  border-radius: 0 !important;
}
.body-widget-category-children-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 140px;
  position: relative;
  border-radius: 8px;
}
.body-widget-category-children__image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 8px;
  object-fit: cover;
}
.body-widget-category-children__image-overlay {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.8;
  transform: scaleY(-1);
  background-image: linear-gradient(180deg, rgba(19, 19, 25, 0) 0%, #131319 100%);
}
.body-widget-category-children__content {
  position: absolute;
  flex-basis: 164px;
  top: 0;
  left: 0;
  padding: 16px;
  width: calc(100% - 32px);
}
.body-widget-category-children__content__title {
  .h4-white;
}
.body-widget-category-children__highlighted-text {
  position: absolute;
  right: 16px;
  bottom: 16px;
  padding: 2px 8px;
  border-radius: 16px;
  height: 20px;
  .caption-white;
}
</style>
